<template lang="html">
  <footer id="footer">
    <section class="newsletter-section">
      <h2>Recibe tips de organización, suscríbete al newsletter.</h2>

      <b-form @submit.prevent="onSubmit">
        <b-form-group>
          <b-form-input
            v-model="form.email"
            type="email"
            required
            placeholder="Email"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-send">Enviar</b-button>
      </b-form>
    </section>

    <section class="footer-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-sm-6 col-lg col-footer">
            <h6>Dirección</h6>
            <p v-html="info.address"></p>
          </div>

          <div class="col-sm-6 col-lg col-footer">
            <h6>Contacto</h6>
            <p>
              <a target="_blank" href="tel:5213335702641"><i class="fas fa-phone-alt mr-1 icon-1"></i> 33 3570 2641</a>
            </p>
          </div>

          <div class="col-lg col-logo">
            <router-link to="/">
              <img src="public/images/logo-white.png">
            </router-link>
          </div>

          <div class="col-sm-6 col-lg col-footer col-r">
            <h6>Email</h6>

            <p>
              contacto@melissaorganiza.com
            </p>
          </div>

          <div class="col-sm-6 col-lg col-footer col-r">
            <h6>Ayuda</h6>

            <p>
              <router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link>
            </p>
            <p>
              <router-link to="/terminos-y-condiciones">Términos y condiciones</router-link>
            </p>
            <!-- <p>
              <router-link to="/politicas-de-pago">Politicas de pago</router-link>
            </p>
            <p>
              <router-link to="/politicas-de-envio">politicas de envio</router-link>
            </p> -->

            <!-- <p>
              <router-link to="">Preguntas Frecuentes</router-link>
            </p> -->
          </div>

          <div class="col-12 col-networks">
            <div class="d-block">
              <a class="trans-250 link" target="_blank" href="https://web.facebook.com/Melissa_organiza-1208507272634982"><i class="fab fa-facebook-square"></i></a>
              <a class="trans-250 link" target="_blank" href="https://www.instagram.com/melissa_organiza/"><i class="fab fa-instagram"></i></a>
              <!-- <a class="trans-250 link" target="_blank" href=""><i class="fab fa-twitter-square"></i></a> -->
            </div>
          </div>
        </div>
      </div>
    </section>

  </footer>
</template>

<script>
export default {
  data(){
    return{
      form: {
        email: null
      },

      info: {
        telefono: '',
        email: '',
        facebook: '#',
        instagram: '#',
        youtube: '',
        address: 'Avenida Vallarta #4820<br />Interior 12, C.P. 45120<br />Col. Juan Manuel Vallarta'
      }
    }
  },

  methods:{

    makeToast(variant = null, msg, title) {
        this.$bvToast.toast(msg, {
          title: title,
          variant: variant,
          solid: true,
          toaster: 'b-toaster-top-right',
          appendToast: true
        });
    },

    onSubmit(evt) {
      evt.preventDefault();
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        axios.post(tools.url("/api/newsletter"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },

    getInfo: function(){
      axios.get(tools.url('/api/info')).then((response)=>{
        this.info = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }

  },

  beforeMount(){
    this.getInfo();
  }
}
</script>
