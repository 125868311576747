<template lang="html">
  <div id="home-page">


    <section class="container-fluid banner-section">
      <swiper class="swiper-desktop" :options="bannersOption">
        <swiper-slide v-if="banner.device == 'pc'" v-for="(banner, index) in banners" :key="index">
          <div class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper-mobile" :options="bannersOption">
        <swiper-slide v-if="banner.device == 'movil'" v-for="(banner, index) in banners" :key="index">
          <div class="d-block placed-backg" v-bind:style="{ backgroundImage: 'url('+banner.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="container oversized-container products-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">Productos favoritos</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="productsOption">
            <swiper-slide v-for="(product, prhInx) in products" :key="'prhInx-'+prhInx">
              <div class="col-12 px-0 sample-product shad-prod">
                <router-link class="outlined-box" :to="'/product/'+$root._converToURL(product.name, product.id)">
                  <div class="box-image">
                    <!-- <div class="out-of-stock">Sin stock</div> -->

                    <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url('+product.img+')' }">
                      <img src="public/images/shared/empty.png" >
                    </div>
                  </div>

                  <div class="box-shot-descr">
                    <h6 class="brand">{{ product.sku }}</h6>
                    <h6 class="name">{{ product.name }}</h6>
                    <!-- <h6 class="price discount"><span>{{ product.price }}</span></h6> -->
                    <h6 class="price">{{ product.price }}</h6>
                  </div>
                  <p class="p-button">
                    <a class="btn-more">Ver producto</a>
                  </p>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <!-- <section class="categories-section">
      <div class="container oversized-container">
        <div class="row justify-content-center">
          <div class="col-12 col-title">
            <h2>CATEGORÍAS</h2>
          </div>

          <div class="col-md-6 col-lg-4 col-xl-3 col-info" v-for="(category, chInx) in categories" :key="'hc-'+chInx">
            <router-link class="box-info" v-bind:style="{ backgroundImage: 'url('+category.img+')' }" to="/productos">
              <img class="img" :src="category.img">

              <div class="inside">
                <h5>{{ category.name }}</h5>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section> -->

    <section class="container oversized-container pb-4 products-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">Proyectos destacados</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12 px-0 col-products">
          <swiper class="swiper carousel-t1 ct1-white" :options="blogOption">
            <swiper-slide class="p-0" v-for="(blog, index) in blogs" :key="index">
              <div class="col-12 px-0 col-article-sample-1">
                <router-link class="box-article" :to="'/proyectos/'+blog.id">
                  <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.image_url+')' }">
                    <img src="public/images/shared/empty.png">
                  </div>

                  <div class="box-descr">
                    <h5>{{ blog.title }}</h5>
                    <div class="descr" v-html="blog.body"></div>
                  </div>
                </router-link>
              </div>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </section>

    <section class="instagram-section">
      <div class="col-12 px-0 col-s-title">
        <h2 class="title no-capita">Síguenos en Instagram</h2>
      </div>

      <div class="elfsight-app-5ab324fe-4ffa-4603-8ecc-4d77f6cb6455"></div>
    </section>

    <!-- <section class="info-section">
      <div class="container oversized-container">
        <div class="row justify-content-center">
          <div class="col-lg-11">
            <div class="row justify-content-between">
              <div class="col-lg-3 col-info">
                <h5 class="title">Particular</h5>

                <div class="box-icon">
                  <img src="public/images/pages/home/icon-particular.svg">
                </div>

                <ul>
                  <li>Compras desde 1 pieza.</li>
                  <li>Somos productores, garantizamos tu entrega en tiempo y forma.</li>
                  <li>Forma de pago variadas.</li>
                  <li>Puedes bordar el logo de tu empresa.</li>
                </ul>
              </div>

              <div class="col-lg-3 col-info">
                <h5 class="title">Distribuidores</h5>

                <div class="box-icon">
                  <img src="public/images/pages/home/icon-distribuidores.svg">
                </div>

                <ul>
                  <li>Conviértete en distribuidor de la marca líder en calidad de moda corporativa.</li>
                  <li>Somos fabricantes, te damos el mejor precio.</li>
                </ul>
              </div>

              <div class="col-lg-3 col-info">
                <h5 class="title">Empresas</h5>

                <div class="box-icon">
                  <img src="public/images/pages/home/icon-empresas.svg">
                </div>

                <ul>
                  <li>A partir de 25 piezas.</li>
                  <li>Mejoramos el precio a mayor volumen.</li>
                  <li>Producimos la cantidad que necesites.</li>
                  <li>Puedes bordar el logo de tu empresa.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- <section class="container oversized-container pb-4 brands-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h2 class="title">Marcas Distribuidas</h2>
        </div>

        <div class="col-12 col-brands">
          <swiper class="swiper" :options="brandsOptions">
            <swiper-slide v-for="(brand, brhInx) in brands" :key="'brhInx-'+brhInx">
              <div class="box-brand">
                <div class="inside">
                  <router-link class="outlined-box" :to="'/marca/'+$root._converToURL(brand.name, brand.id)">
                    <img :src="brand.imageUrl">
                  </router-link>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>

    </section> -->

  </div>
</template>

<script>
export default {
  data(){
    return{
      banners: [],
      products: [],
      blogs: [],

      categories: [],
      brands: [],

      /* == Carousel options == */
      bannersOption: {
        loop: false,
        speed:800,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
      },

      productsOption: {
        loop: false,
        slidesPerView: 5,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1399: {
            slidesPerView: 5
          },
          1199: {
            slidesPerView: 4
          },
          991: {
            spaceBetween: 30,
            slidesPerView: 3
          },
          575: {
            spaceBetween: 20,
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      blogOption: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 0,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          992: {
            slidesPerView: 3
          },
          576: {
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      },
      brandsOptions: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },

        breakpoints: {
          992: {
            slidesPerView: 3,
          },
          370: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      }
      // == ==
    }
  },

  methods: {
    getHome: function(){
        axios.get(tools.url('/api/home')).then(({data}) =>{
            this.banners = data.banners;
            this.products = data.destacados;
            this.ofertas = data.ofertas;
            this.blogs = data.blog;
        });
    }
  },

  mounted(){
    this.getHome();
  }

}
</script>
