<template lang="html">
  <div id="services-page">

    <section class="container oversized-container main-section">
      <div class="row align-items-center">
        <!-- <div class="col-md-5 col-lg-6 col-image">
          <img src="public/images/pages/xxxxx/image.jpg">
        </div> -->

        <div class="col-12 col-info">
          <div class="pb-1 col-s-title">
            <h2 class="title text-center">Servicios</h2>
          </div>

          <h6 class="text-center subtitle">
            Estos son algunos de los servicios que ofrecemos.
          </h6>

          <div class="row box-services">
            <div class="col-sm-6 col-lg-4 col-xl-3 col-service">
              <div class="circle">
                <img class="placed-backg" src="public/images/pages/services/image-1.jpg">
              </div>

              <h5 class="title">Organización de espacios para casa, oficina, comercio e industria</h5>
            </div>

            <div class="col-sm-6 col-lg-4 col-xl-3 col-service">
              <div class="circle">
                <img class="placed-backg" src="public/images/pages/services/image-2.jpg">
              </div>

              <h5 class="title">Capacitaciones</h5>
            </div>

            <div class="col-sm-6 col-lg-4 col-xl-3 col-service">
              <div class="circle">
                <img class="placed-backg" src="public/images/pages/services/image-3.jpg">
              </div>

              <h5 class="title">Mantenimientos</h5>
            </div>

            <div class="col-sm-6 col-lg-4 col-xl-3 col-service">
              <div class="circle">
                <img class="placed-backg" src="public/images/pages/services/image-4.jpg">
              </div>

              <h5 class="title">Cursos y platicas</h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <div class="placed-backg img-bg" v-bind:style="{ backgroundImage: 'url(public/images/pages/xxxxx/image.jpg)' }"></div> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/nosotros')).then((response)=>{
        this.content = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
