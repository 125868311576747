<template lang="html">
    <div class="placed-backg faqs-page" id="page-info-t1">
        <!-- <div class="bg-image"></div> -->
        <section class="container oversized-container content-section">
            <div class="row align-items-center justify-content-center" v-bind:class="{ 'tabla-page' : $route.params.table == 'tabla' }">
                <div class="col-lg-10 col-info" id="faqs-box">
                    <h1 class="mb-4 page-title">Aviso de privacidad</h1>
                    <div v-html="content"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/privacy')).then((response)=>{
        this.content = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    this.getContent();
  }
}
</script>
