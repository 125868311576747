<template lang="html">
  <div id="article-page">

    <div class="container content-section">
      <div class="row">
        <div class="col-12 col-s-title">
          <h5 class="title">Proyectos</h5>
        </div>
      </div>

      <div class="row">
        <!-- Artículo actual -->
        <div class="col-lg-8 col-article">
          <div class="mb-3 box-image">
            <img :src="noticia.image_url">
          </div>
          <h1 class="title">{{ noticia.title }}</h1>
          <div class="descr" v-html="noticia.body"></div>
        </div>
        <!--  -->

        <!-- Artículos -->
        <div class="col-lg-4 col-blog">
          <div class="row">
            <div class="col-12 col-article-sample-1 sample-2" v-for="(blog, arInx) in blogs" :key="'ar-'+arInx">
              <router-link class="box-article" :to="'/tutoriales/'+blog.id">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.image_url+')' }">
                  <img src="public/images/shared/empty.png">
                </div>

                <div class="box-descr">
                  <h5>{{ blog.title }}</h5>

                  <div class="descr" v-html="blog.body"></div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      noticia: [],
      blogs: [],
      id: null,
    }
  },

    watch: {
        "$route.params.id" : function(v){
            this.id = this.$root._getURLID(this.$route.params.id);
            this.getBlogs();
        },
    },

  methods: {
    getBlogs: function(){
      axios.get(tools.url('/api/projects/'+this.id)).then((response)=>{
          this.noticia = response.data.project;
          this.blogs = response.data.projects;
      });
    }
  },

  mounted(){
    this.id = this.$root._getURLID(this.$route.params.id);
    this.getBlogs();
  }
}
</script>
