<template lang="html">
  <div class="placed-backg contact-page-" id="page-info-t1">
    <div class="bg-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/contact/bg.jpg)' }"></div>

    <section class="container-fluid content-section">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 text-center col-info">
          <!-- <h1 class="mb-2 page-title">Contact</h1> -->

          <!-- <p class="mt-2">
            Escríbenos o llámanos para agendar una cita.
          </p>

          <hr />
          <h5 class="subtitle-sm">Dirección:</h5>
          <p>
            Av Diamante 2680,<br />
            Col. Bosques de la Victoria,<br />
            45050 Zapopan, Jalisco.
          </p>

          <br />
          <h5 class="subtitle-sm">Contacto:</h5>
          <p>
            <strong class="f-w-600">Tel:</strong>33 3333 3333<br />
          </p>

          <br />
          <h5 class="subtitle-sm">Email:</h5>
          <p>
            <strong class="f-w-600">contacto@melissaorganiza.com</strong>
          </p> -->
        </div>

        <div class="col-lg-6 col-info">
          <div class="box-middle">
            <h1 class="page-title">Contacto</h1>

            <b-form @submit="onSubmit">
              <b-form-group>
                <b-form-select required v-model="form.service">
                  <b-form-select-option :value="null">¿Qué servicio te interesa?</b-form-select-option>
                  <b-form-select-option value="Mudanza">Mudanza</b-form-select-option>
                  <b-form-select-option value="Organización de espacios">Organización de espacios</b-form-select-option>
                  <b-form-select-option value="Cursos y talleres">Cursos y talleres</b-form-select-option>
                  <b-form-select-option value="Colaboración con mi marca">Colaboración con mi marca</b-form-select-option>
                  <b-form-select-option value="Capacitación corporativa">Capacitación corporativa</b-form-select-option>
                </b-form-select>
              </b-form-group>

              <b-form-group>
                <b-form-input
                  v-model="form.name"
                  type="text"
                  required
                  placeholder="Nombre"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-input
                  v-model="form.email"
                  type="email"
                  required
                  placeholder="Email"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-input
                  v-model="form.phone"
                  type="text"
                  required
                  minlength="10"
                  maxlength="10"
                  placeholder="Teléfono"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-textarea
                  v-model="form.msg"
                  placeholder="Mensaje"
                  required
                  rows="4"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <p class="text-center">
                <b-button type="submit" class="btn-send">Enviar</b-button>
              </p>
            </b-form>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="container-fluid px-0 map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d240863.6849574142!2d-99.28369723712339!3d19.391003837024297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce0026db097507%3A0x54061076265ee841!2sCiudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses!2smx!4v1637017314921!5m2!1ses!2smx" allowfullscreen="" loading="lazy"></iframe>
    </section> -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        service: null,
        name: null,
        email: null,
        phone: null,
        msg: null
      },
      info: [],
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      })
    },

    getInfo: function(){
      axios.get(tools.url('/api/info')).then((response)=>{
        this.info = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },

    onSubmit(evt) {
      evt.preventDefault();
      //var data=tools.params(form, this.form);
      axios.post(tools.url("/api/contact"), this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Mensaje enviado');
          this.form = {
            service: null,
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
        // console.log(error.response);
        let errors = error.response.data;
        // console.log(errors);
        this.makeToast('danger', error.response.data.service, 'Error');
        this.makeToast('danger', error.response.data.name, 'Error');
        this.makeToast('danger', error.response.data.email, 'Error');
        this.makeToast('danger', error.response.data.phone, 'Error');
        this.makeToast('danger', error.response.data.msg, 'Error');
      });
    }
  },

  beforeMount(){
    // this.getInfo();

    if(this.$route.query.p){
      this.form.msg = 'Estoy interesado en el producto '+ this.$route.query.p;
    }
  }
}
</script>
