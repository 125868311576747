<template lang="html">
  <div id="move-page">

    <section class="container-fluid main-section">
      <div class="row align-items-center">
        <div class="col-md-6 col-image">
          <!-- <img src="public/images/pages/about-us/image.jpg"> -->
        </div>

        <div class="col-md-6 col-info">
          <div class="box-middle">
            <div class="col-s-title">
              <h2 class="title">Mudanzas</h2>
            </div>
            <!-- <div v-html="content"></div> -->
            <div class="text-justify">
              <p>
                Te ayudamos a organizar y clasificar, empacar (emplayar) y desempacar, acomodar y a convertir tu casa en un hogar, sin que tengas que preocuparte por nada porque cuidamos todo como si fuera nuestro.
              </p>
              <p class="mt-3">
                Movemos todo; muebles, ropa, despensa, electrodomésticos, electrónicos, artículos personales, juguetes etc… <br />
                Dejamos todo ordenado e impecable.
              </p>
              <p class="mt-3">
                Somos especialistas en mudanzas, contamos con infraestructura de transporte para carga, personal capacitado y la experiencia para mover todo de una casa a otra de manera local y nacional.
              </p>

              <p class="mt-4 text-center">
                <router-link class="trans-250 btn-contact" to="/contacto">Cotiza tu mudanza ahora</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="placed-backg img-bg" v-bind:style="{ backgroundImage: 'url(public/images/pages/move/image.jpg)' }"></div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/mudanzas')).then((response)=>{
        this.content = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
