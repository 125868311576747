<template lang="html">
  <div id="header">
    <div class="header-content">

      <!-- Payments -->
      <div class="box-buy">
        <div class="container oversized-container d-flex align-items-center">
          <div class="col col-lg-7 col-xl col-ml">
            <h5>Envío gratis en compras mayores a $1,000 pesos</h5>
          </div>
          <!-- <div class="col col-lg-3 col-xl col-ssl">
            <img src="public/images/shared/ssl1.png">
            <span>A 246 bits</span>
          </div> -->
          <div class="col-lg-5 col-xl-6 col-logos">
            <span>Paga con tu tarjeta de débito o crédito</span>
            <i class="fab fa-cc-mastercard icon"></i>
            <i class="fab fa-cc-visa icon"></i>
            <i class="fab fa-cc-amex icon"></i>
            <!-- <img class="img-icon" src="public/images/shared/oxxo.png" alt="Oxxo"> -->
          </div>
        </div>
      </div>
      <!--  -->

      <!-- Navbar -->
      <b-navbar toggleable="lg" type="light" variant="default">
        <div class="container oversized-container">

          <b-navbar-brand to="/">
            <img class="logo" src="public/images/logo-white-center.png">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"><i class="fas fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" class="header-navigation" is-nav>
            <b-navbar-nav class="header-menu">
              <!-- <b-nav-item class="simple-item" to="/">Inicio</b-nav-item> -->
              <li class="nav-item d-lg-none">
                <a class="nav-link dropdown-toggle" @click="isPActive = !isPActive">Productos</a>
              </li>
              <b-nav-item class="simple-item" to="/empresa">Empresa</b-nav-item>
              <b-nav-item class="simple-item" to="/proyectos">Proyectos</b-nav-item>
              <b-nav-item class="simple-item" to="/videos">Videos</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/organizacion-de-espacios">Organización de espacios</b-nav-item>
              <!-- <b-nav-item class="simple-item" to="/faqs">FAQ's</b-nav-item> -->
              <!-- <b-nav-item class="simple-item d-lg-none" to="/servicios">Servicios</b-nav-item> -->
              <b-nav-item class="simple-item d-lg-none" to="/mudanzas">Mudanzas</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/cursos-y-talleres">Cursos y talleres</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/colaboraciones">Colaboraciones</b-nav-item>
              <b-nav-item class="simple-item" to="/contacto">Contacto</b-nav-item>
              <!-- <b-nav-item-dropdown right>
                <template #button-content>
                  <span><i class="far fa-sort-amount-down"></i> Filtrar por área</span>
                </template>
                <b-dropdown-item to="">Cocina</b-dropdown-item>
                <b-dropdown-item to="">Closet</b-dropdown-item>
                <b-dropdown-item to="">Oficina</b-dropdown-item>
                <b-dropdown-item to="">Baño</b-dropdown-item>
                <b-dropdown-item to="">Despensa</b-dropdown-item>
              </b-nav-item-dropdown> -->

              <li class="nav-item simple-item d-lg-none">
                <div class="nav-link">
                  <hr class="my-0" />
                </div>
              </li>
              <b-nav-item class="simple-item cart-item d-lg-none" to="/cart"><i class="far fa-shopping-cart icon"></i> Mi carrito <i class="num">{{ $root.cartCount }}</i></b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/usuario" v-if="$root.logged"><i class="far fa-user user-icon"></i>&nbsp; Mi cuenta</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/login" v-if="!$root.logged"><i class="far fa-user user-icon"></i>&nbsp; Inicar sesión</b-nav-item>
              <b-nav-item class="simple-item d-lg-none" to="/registrarse" v-if="!$root.logged"><i class="fas fa-user-plus"></i> Registrarse</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-form class="search-form order-1 order-lg-0" @submit.prevent="searchForm">
                <b-form-input size="sm" class="mr-sm-2" placeholder="Buscar..." v-model="search.keywords"></b-form-input>
                <b-button class="btn-search" type="submit"></b-button>
              </b-nav-form>

              <b-nav-item-dropdown class="extra-menu order-0 order-lg-1 d-none d-lg-flex" right>
                <template slot="button-content"><i class="far fa-user dropdown-icon"></i></template>
                <b-dropdown-item to="/usuario" v-if="$root.logged">Mi cuenta</b-dropdown-item>
                <b-dropdown-item to="/login" v-if="!$root.logged">Iniciar sesión</b-dropdown-item>
                <b-dropdown-item to="/registrarse" v-if="!$root.logged">Registrarse</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item class="cart-item d-none d-lg-flex" to="/cart"><i class="far fa-shopping-cart cart-icon"><i class="num">{{ $root.cartCount }}</i></i></b-nav-item>
            </b-navbar-nav>
          </b-collapse>

        </div>
      </b-navbar>
      <!--  -->

      <!-- Header bottom -->
      <div class="header-bottom">
        <b-navbar toggleable="lg" type="dark" variant="info">
          <div class="container oversized-container px-1">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="mr-auto">
                <li class="nav-item b-nav-dropdown dropdown">
                  <a class="nav-link dropdown-toggle" @click="isPActive = !isPActive">Productos</a>
                </li>

                <b-nav-item to="/organizacion-de-espacios">Organización de espacios</b-nav-item>
                <!-- <b-nav-item to="/servicios">Servicios</b-nav-item> -->
                <b-nav-item to="/mudanzas">Mudanzas</b-nav-item>
                <b-nav-item to="/cursos-y-talleres">Cursos y talleres</b-nav-item>
                <b-nav-item to="/colaboraciones">Colaboraciones</b-nav-item>
                <!-- <b-nav-item to="/preguntas-frecuentes/tabla">Tablas de medidas</b-nav-item> -->

                <!-- <b-nav-item-dropdown right>
                  <template v-slot:button-content>
                    <span>Catálogos</span>
                  </template>
                  <li  v-for="(catalogo, pcatIndex) in catalogos" :key="index">
                    <a class="dropdown-item" target="_blank" :href="catalogo.file">{{ catalogo.name }}</a>
                  </li>
                </b-nav-item-dropdown> -->

                <!-- <b-nav-item to="/distribuidores"><i class="fas fa-tshirt"></i> Distribuidores</b-nav-item> -->
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </div>
      <!--  -->

      <!-- Products menu -->
      <div class="trans-250 products-menu" v-bind:class="{ active: isPActive }">
        <div class="menu-bg" @click="isPActive = false"></div>

        <div class="tablist" role="tablist">
          <div class="trans-250 box-title" @click="isPActive = false">
            <h4>PRODUCTOS</h4>
            <br>
            <!-- <h5 v-on:click="$router.push('/');">Categorías</h5> -->
            <i class="fas fa-chevron-left icon"></i>
          </div>

          <b-card no-body class="mb-1" v-for="(category, pcatIndex) in categories" :key="pcatIndex">
            <b-card-header header-tag="header" role="tab">
              <a v-if="category.subcategories" class="btn-toggle" v-b-toggle="'accordion-'+pcatIndex">{{ category.name }}</a>
              <router-link v-else class="btn-toggle" :to="'/categoria/'+$root._converToURL(category.name, category.id)">{{ category.name }}</router-link>
            </b-card-header>

            <b-collapse v-if="category.subcategories" :id="'accordion-'+pcatIndex" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <ul class="ul-sub-options">
                  <li v-for="subcategory in category.subcategories">
                    <router-link class="trans-250" :to="'/categoria/'+$root._converToURL(subcategory.name, subcategory.id)">{{ subcategory.name }}</router-link>
                  </li>
                </ul>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <!--  -->

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      isPActive: false,
      isDActive: false,

      categories:[],
      catalogos: [],

      search: {
        keywords: null
      }
    }
  },

  methods:{
    searchForm(evt){
        this.$router.push({path: '/busqueda', query: {keywords:this.search.keywords}});
    },

    getCategories: function(){
      axios.get(tools.url('/api/categories')).then((response)=>{
        this.categories = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    },
  },

  watch: {
    $route (to, from){
      // Ocultar menu cada vez que cambie la ruta
      this.isPActive = false;
      this.isDActive = false;
    }
  },

  beforeMount(){
    this.getCategories();
  }
}
</script>
