<template lang="html">
  <div id="videos-page">

    <section class="container oversized-container main-section">
      <div class="col-s-title">
        <h2 class="title text-center">Videos</h2>
      </div>

      <div class="row box-videos">
        <div class="col-12 col-md-6 col-video" v-for="(video, index) in videos" :key="index">
          <iframe :src="'https://www.youtube.com/embed/'+video+'?rel=0'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      videos: [],
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/videos')).then((response)=>{
        this.videos = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    this.getContent();
  }
}
</script>
