<template lang="html">
  <div id="collaborations-page">

    <section class="container-fluid main-section">
      <div class="row align-items-center">
        <div class="col-md-6 col-image">
          <!-- <img src="public/images/pages/about-us/image.jpg"> -->
        </div>

        <div class="col-md-6 col-info">
          <div class="box-middle">
            <div class="col-s-title">
              <h2 class="title">Colaboraciones</h2>
            </div>
            <!-- <div v-html="content"></div> -->
            <div class="text-justify">
              <p>
                Melissa Organiza puede apoyarte en colaborar con tu marca vía redes sociales para lograr mayor exposición, proyección y trafico.
              </p>
              <p class="mt-3">
                Proveemos paquetes de creación de contenido digital, reviews y compartido de publicaciones a empresas y particulares de las áreas de:
              </p>
              <ul>
                <li>Productos para organización.</li>
                <li>Productos de oficina.</li>
                <li>Muebles.</li>
                <li>Productos de cocina.</li>
                <li>Productos saludables.</li>
                <li>Productos ecológicos.</li>
              </ul>

              <p>Conoce nuestra cuenta de Instagram:</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="placed-backg img-bg" v-bind:style="{ backgroundImage: 'url(public/images/pages/collaborations/image.jpg)' }"></div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/mudanzas')).then((response)=>{
        this.content = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
