import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '*', component: require(page+'404.vue').default, meta:{title:"Not found"} },
      { path: '/', component: require(page+'home.vue').default },
      { path: '/proyectos', component: require(page+'projects/index.vue').default, meta:{title:"Proyectos"}},
      { path: '/proyectos/:id', component: require(page+'projects/detail.vue').default, meta:{title:"Proyecto"}},

      { path: '/empresa', component: require(page+'about-us/index.vue').default, meta:{title:"Nosotros"}},
      { path: '/servicios', component: require(page+'services/index.vue').default, meta:{title:"Servicios"}},
      { path: '/organizacion-de-espacios', component: require(page+'spaces/index.vue').default, meta:{title:"Organización de espacios"}},
      { path: '/mudanzas', component: require(page+'move/index.vue').default, meta:{title:"Mudanzas"}},
      { path: '/cursos-y-talleres', component: require(page+'courses/index.vue').default, meta:{title:"Cursos"}},
      { path: '/videos', component: require(page+'videos/index.vue').default, meta:{title:"Videos"}},
      { path: '/colaboraciones', component: require(page+'collaborations/index.vue').default, meta:{title:"Colaboraciones"}},
      { path: '/contacto', component: require(page+'contact/index.vue').default, name: 'contacto', meta:{title:"Contacto"}},
      // { path: '/faqs/:table?', component: require(page+'faqs/index.vue').default, meta:{title:"FAQs"}},
      { path: '/aviso-de-privacidad', component: require(page+'text-page/aviso.vue').default, meta:{title:"Aviso de privacidad"}},
	    { path: '/terminos-y-condiciones', component: require(page+'text-page/terminos.vue').default, meta:{title:"Términos y condiciones"}},

      { path: '/products', component: require(page+'products/results.vue').default, meta:{title:"Productos"}},
      { path: '/product/:id?', component: require(page+'products/description.vue').default, meta:{title:"Producto"}},
      { path: '/categoria/:id?', component: require(page+'products/category.vue').default, meta:{title:"Categoría"}},
      { path: '/busqueda', component: require(page+'products/results.vue').default, meta:{title:"Búsqueda"}},

      { path: '/cart/:step?', component: require(page+'carrito/index.vue').default, meta:{title:"Mi carrito de compras"}},
      { path: '/historial-de-compras', component: require(page+'carrito/historial.vue').default, meta:{title:"Mi historial de compras"}},

      { path: '/login', component: require(page+'user-access/login.vue').default, meta:{title:"Iniciar sesión"}},
      { path: '/recuperar-contrasena', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Recuperar contraseña"}},
      { path: '/registrarse', component: require(page+'user-access/register.vue').default, meta:{title:"Registrarse"}},

      { path: '/usuario', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
  			{
  				path: '/',
  				component: require(page+'usuario-cuenta/mis-datos.vue').default,
  				meta:{ title: 'Mis datos' }
  			},
  			{
  				path: 'contrasena',
  				component: require(page+'usuario-cuenta/contrasena.vue').default,
  				meta:{ title: 'Cambiar contraseña' }
  			},

  			// {
  			//   path: 'nueva-cotizacion',
  			//   component: require(page+'usuario-cuenta/nueva-cotizacion.vue'),
  			//   meta:{ title: 'Mis cotizaciones' }
  			// },
  			// {
  			//   path: 'mis-cotizaciones',
  			//   component: require(page+'usuario-cuenta/mis-cotizaciones.vue'),
  			//   meta:{ title: 'Mis facturas' }
  			// }
  	  	]
  		}

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},50);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Melissa Organiza", filter: (title)=>{ return title+" - Melissa Organiza"; } }
);

// export {routes};
export default MyRouter;
