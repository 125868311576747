<template lang="html">
  <div id="courses-page">

    <section class="container-fluid main-section">
      <div class="row align-items-center">
        <!-- <div class="col-md-5 col-lg-6 col-image">
          <img src="public/images/pages/about-us/image.jpg">
        </div> -->

        <div class="col-md-6 col-info">
          <div class="box-middle">
            <div class="col-s-title">
              <h2 class="title">Cursos y talleres</h2>
            </div>
            <!-- <div v-html="content"></div> -->
            <div class="text-justify">
              <p>
                Hemos compactado nuestra experiencia en cursos cortos y simplificados para permitir que cualquier persona comprenda como crear un sistema de orden para su espacio.
              </p>
              <p class="mt-3">
                Contamos con capacitaciones en streamig, cursos virtuales y presenciales y platicas para empresas u organizaciones relacionadas a como optimizar el día a día a través del orden.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="placed-backg img-bg" v-bind:style="{ backgroundImage: 'url(public/images/pages/courses/image.jpg)' }"></div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/mudanzas')).then((response)=>{
        this.content = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
