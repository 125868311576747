<template lang="html">
  <div class="flex-center-xy" id="error-empty">
    <div class="inside">
      <p class="mb-3">
        <img class="img-logo" src="public/images/logo.png">
      </p>
      <h1>404. Pagina no encontrada</h1>
    </div>
  </div>
</template>

<script>
export default {
}
</script>
