<template lang="html">
  <div id="about-us-page-v2">

    <section class="container-fluid main-section">
      <div class="row align-items-center">
        <div class="col-md-5 col-lg-6 col-image">
          <!-- <img src="public/images/pages/about-us/image.jpg"> -->
        </div>

        <div class="col-md-7 col-lg-6 col-info">
          <div class="box-middle">
            <div class="col-s-title">
              <h2 class="title">Empresa</h2>
            </div>
            <!-- <div v-html="content"></div> -->
            <div class="">
              <p>
                Te ayudamos a solucionar problemas relacionados con el orden a través de la depuración consciente de tus espacios por medio de un asesoramiento personal y sensible a tus necesidades para crear un sistema de organización efectivo y permanente en tu casa, trabajo, comercio, y cualquier espacio, queremos mejorar tu calidad de vida a través del orden.
              </p>
              <p class="mt-3">
                Melissa Organiza cuenta con mas de 5 años de experiencia en la proveeduria de servicios de organización, capacitaciones y mudanzas. Tiene un equipo capacitado y de confianza que transformara tu espacio en tiempo record y con el mejor diseño.
              </p>
              <p class="mt-3">
                Nuestra misión es ayudar a las personas organizar su día a día por medio de el re diseño de espacios, cambio de mobiliario y técnicas de orden sencillas, efectivas y de rápidos resultados
              </p>

              <p class="mt-4 text-center">
                <router-link class="trans-250 btn-contact" to="/contacto">¡Contactanos y comencemos a platicar!</router-link>
              </p>
          </div>
          </div>
        </div>
      </div>
    </section>

    <div class="placed-backg img-bg" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/image.jpg)' }"></div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      content: '',
    }
  },

  methods: {
    getContent: function(){
      axios.get(tools.url('/api/nosotros')).then((response)=>{
        this.content = response.data;
      }).catch((error)=>{
        console.log(error);
      })
    }
  },

  beforeMount(){
    // this.getContent();
  }
}
</script>
